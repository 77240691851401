<template>
    <div class="add-ons">
        <HeadingComponent
            title="Additional <br>application services."
            subtitle="Keep customizing your application packages with more options"
        />

        <template>
            <div class="mt-10 add-ons__cards-container">
                <v-card elevation="0" class="card card--standard">
                    <h4>
                        Deluxe custom application
                        <v-btn x-small icon
                            ><img src="../../assets/icons/warning.svg" alt="helper"
                        /></v-btn>
                    </h4>
                    <div class="card__group card__group--with-action">
                        <p>
                            Convert your current application/screening packet into an easy to fill
                            the electronic document. Data collected on-line through the Tenant
                            Evaluation website will be automatically transferred into your
                            electronic application screening package, reducing workload and making
                            the process easier for your applicants.
                        </p>
                        <div class="button-container">
                            <v-btn depressed class="secondary">Add</v-btn>
                        </div>
                    </div>
                    <div class="card__actions">
                        <div class="form__group">
                            <v-select
                                class="v-input--compressed"
                                :items="deluxeApplicationOptions"
                                :value="deluxeApplicationOptions[0]"
                                filled
                                hide-details="auto"
                            ></v-select>
                        </div>
                        <v-spacer></v-spacer>
                        <div class="item-price">
                            <span class="item-price__info mr-3">One time fee</span>
                            <span>$200.<small>00</small></span>
                        </div>
                    </div>
                </v-card>

                <v-card elevation="0" class="card card--standard">
                    <h4>
                        Premium document collection
                        <v-btn x-small icon
                            ><img src="../../assets/icons/warning.svg" alt="helper"
                        /></v-btn>
                    </h4>
                    <div class="card__group card__group--with-action">
                        <p>
                            Your basic package includes Photo ID, Marriage certificate and pet
                            photo. You can add collect more documents from the following options
                        </p>
                        <div class="button-container">
                            <v-btn depressed class="secondary">Add</v-btn>
                        </div>
                    </div>
                    <div class="card__actions">
                        <div class="form__group">
                            <v-select
                                class="v-input--compressed"
                                :items="documentCollectionOptions"
                                :value="documentCollectionOptions[0]"
                                filled
                                hide-details="auto"
                            ></v-select>
                        </div>
                        <v-spacer></v-spacer>
                        <div class="item-price">
                            <span>$5.<small>00</small></span>
                        </div>
                    </div>
                </v-card>

                <v-card elevation="0" class="card card--standard">
                    <h4>
                        Expedite application processing <span class="highlight ml-1">New</span>
                        <v-btn x-small icon
                            ><img src="../../assets/icons/warning.svg" alt="helper"
                        /></v-btn>
                    </h4>
                    <div class="card__group card__group--with-action">
                        <p>
                            If you would like applications to be expedited on your end, please state
                            the total amount to be charged to the applicant below. Tenant Evaluation
                            will deduct the $45.00 and provide the remaining balance to the
                            Association Management Company.
                            <br />Applicants will be automatically provided an option to rush their
                            application within Tenant Evaluation’s system
                        </p>
                        <div class="button-container">
                            <v-btn depressed class="secondary">Add</v-btn>
                        </div>
                    </div>
                    <div class="card__actions">
                        <v-spacer></v-spacer>
                        <div class="item-price">
                            <span>$5.<small>00</small></span>
                        </div>
                    </div>
                </v-card>

                <v-card elevation="0" class="card card--standard">
                    <h4>
                        Quick-approve System
                        <v-btn x-small icon
                            ><img src="../../assets/icons/warning.svg" alt="helper"
                        /></v-btn>
                    </h4>
                    <div class="card__group card__group--with-action">
                        <p>
                            This feature is designed to provide an easy-to-use interface for the
                            approval/denial of an applicant with just the click of a button! In
                            addition, this new feature provides timely communication between
                            Management and the Screening Committee. You have the option to select
                            approve/deny and automatically notify management and the applicant(s)
                            via email of the final decision by a copy of the approval/denial letter!
                        </p>
                        <div class="button-container">
                            <v-btn depressed class="secondary">Add</v-btn>
                        </div>
                    </div>
                    <div class="card__actions">
                        <v-spacer></v-spacer>
                        <div class="item-price">
                            <span>$5.<small>00</small></span>
                        </div>
                    </div>
                </v-card>

                <v-card elevation="0" class="card card--standard">
                    <h4>
                        Quick-approve System
                    </h4>
                    <div class="card__group card__group--with-action">
                        <p>
                            This feature is designed to provide an easy-to-use interface for the
                            approval/denial of an applicant with just the click of a button! In
                            addition, this new feature provides timely communication between
                            Management and the Screening Committee. You have the option to select
                            approve/deny and automatically notify management and the applicant(s)
                            via email of the final decision by a copy of the approval/denial letter!
                        </p>
                        <div class="button-container">
                            <v-btn depressed class="secondary">Add</v-btn>
                        </div>
                    </div>
                    <div class="card__actions">
                        <div class="form__group">
                            <label>Would you like to include letter customization?</label>
                            <v-select
                                class="v-input--compressed"
                                :items="documentCollectionOptions"
                                placeholder="Select on option"
                                filled
                                hide-details="auto"
                            ></v-select>
                        </div>
                        <v-spacer></v-spacer>
                        <div class="item-price">
                            <span>$5.<small>00</small></span>
                        </div>
                    </div>
                </v-card>
            </div>

            <div class="dashboard__actions">
                <div class="spacer"></div>
                <v-btn @click="$router.push('services')" color="secondary" x-large elevation="0">
                    continue
                </v-btn>
            </div>
        </template>
    </div>
</template>

<script>
import HeadingComponent from '@/components/HeadingComponent';

export default {
    name: 'AddOns',
    components: {
        HeadingComponent,
    },
    data() {
        return {
            deluxeApplicationOptions: [
                'Standard TE form',
                'Standard TE form 2',
                'Standard TE form 3',
            ],
            documentCollectionOptions: [
                'Up to 5 extra documents',
                'Up to 5 extra documents 2',
                'Up to 5 extra documents 3',
            ],
        };
    },
};
</script>

<style scoped lang="scss">
@import './src/scss/abstracts/variables';
@import './src/scss/abstracts/functions';
</style>
